<template>
	<div class="store">
		<div class="content">
			<div id="qrcode"></div>
			<p v-if="qrCodeUrl">请使用微信扫描二维码进行支付(请在{{ miao }}秒之内支付)</p>
		</div>
	</div>
</template>

<script>
import QRCode from 'qrcode';
import axios from "axios";
export default {
	data () {
		return {
			qrCodeUrl: '', // 用于显示支付二维码的 URL
			out_trade_no: '', //订单号
			intervalId: null, //定时器ID
			intermiao: null, //倒计时ID
			miao: 60
		};
	},
	created () {
		// 提交订单
		this.submitOrder();
	},
	methods: {
		// 提交订单
		submitOrder () {
			const token = window.localStorage.getItem('ckt:token');
			if (!token) {
				this.$message.error('用户身份信息校验失败，请重新登录');
				window.location.href = 'https://nav.csavs.com/login.html';
				return;
			}
			axios.post(
				'https://navadmin.csavs.com/api/ckt/pay',
				{},
				{
					headers: { 'token': token },
				})
				.then(res => {
					if (res.status == 200 && res.data.code == 1) {
						this.qrCodeUrl = res.data.data.code_url;
						this.out_trade_no = res.data.data.out_trade_no;
						// 生成二维码
						this.generateQRCode();
					}
				}, error => this.$message.error('获取支付信息失败，请关闭后重试'));
		},

		// 生成二维码
		generateQRCode () {
			const qrCodeContainer = document.getElementById('qrcode');
			if (qrCodeContainer && this.qrCodeUrl != '' && this.out_trade_no != '') {
				QRCode.toCanvas(this.qrCodeUrl, {
					margin: 1,
					height: 200,
					width: 200,
					scal: 177,
					color: { dark: '#000' },
				}).then(canvas => {
					qrCodeContainer.append(canvas);
					this.intervalId = setInterval(this.checkOrderStatus, 3000);
					this.intermiao = setInterval(this.timeDown, 1000);
					// 设置定时器，在60秒后停止定时器
					setTimeout(() => {
						this.$emit('statusChange', 0);
						// this.$message.warning({	message: '支付超时，请重新支付或查看支付状态'});
					}, this.miao * 1000); // 60秒后停止定时器
				}, error => console.log(err));
			}
		},

		/// 倒计时
		timeDown () {
			this.miao--;
		},

		// 检测订单是否支付成功
		checkOrderStatus () {
			const token = window.localStorage.getItem('ckt:token');

			axios.post(
				'https://navadmin.csavs.com/api/system/checkOrderStatus',
				{
					out_trade_no: this.out_trade_no // 将订单号传递给后端
				},
				{
					headers: { 'token': token },
				})
				.then(res => {
					if (res.data.code == 1) {
						if (res.data.msg == '订单支付成功') {
							this.$emit('statusChange', 1);
							this.$message.success("订单支付成功");
						}
					}
				}, error => {
					console.log(error);
					this.$emit('statusChange', 0);
					this.$message.error('获取支付信息失败，请关闭后重试');
				});
		},
	},
};
</script>

<style scoped lang="less">
.store {
	display: inline-block;
	max-width: 100%;
	max-height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.content {
	color: #000;
	font-size: 12px;
	width: 100%;
	height: 100%;
}
</style>