<template>
  <div class="root">
    <div class="header">
      <img class="logo" src="./assets/logo.png" />
      <img height="84px" src="./assets/title_pic.png">
    </div>
    <!-- 模板设计器的挂载点 -->
    <div id="app"> </div>
    <el-dialog id="dialog" title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div v-if="dialogVisible">
        <orderComp @statusChange="statusChange" ref="orderComp" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import CktDesign from '@chuangkit/chuangkit-design';
import axios from "axios";

import { paramsDesign } from "@/utils/design";
import { downloadImage } from "@/utils/download";

import orderComp from '@/components/order.vue';

export default {
  name: 'App',
  components: {
    orderComp,
  },
  data () {
    return {
      app_skey: "",
      // 支付弹窗
      dialogVisible: false,
      // 用户信息
      userInfo: {},
      // 签名信息
      signInfo: {},
    };
  },
  created () {
    // 鉴权，获取用户信息
    this.getUserInfo();

    // 创可贴回调函数
    window.chuangkitComplete = (res) => {
      // console.log("收到创客贴回调参数:", res);
      if (res['kind'] == 2 && res['source-urls'].length > 0) {
        const token = window.localStorage.getItem('ckt:token');
        if (res.price == 0) {
          // 免费
          var newUrl = `https://${res['source-urls'][0].substring(2)}`;
          downloadImage(newUrl);
        } else if (res.price == 1) {
          // 收费
          if (this.userInfo.number <= 0) {
            var newUrl = `https://${res['source-urls'][0].substring(2)}`;
            downloadImage(newUrl);
          } else {
            axios.post(
              'https://navadmin.csavs.com/api/ckt/jian',
              {},
              {
                headers: { 'token': token },
              })
              .then(response => {
                if (response.status == 200 && response.data.code == 1) {
                  var newUrl = `https://${res['source-urls'][0].substring(2)}`;
                  downloadImage(newUrl);
                }
              }, error => {
                console.error(error);
              });
          }
          this.userInfo.number--;
        }
        return;
      }

      // 前端付费阻断处理——前置付费, 现在应该跳转到第三方购买页
      if (res.type && res.type == "interrupted") {
        return;
      }

      // 前端付费阻断处理——后置付费, 现在应该跳转到第三方会员转化页面
      if (res.type && res.type == "postposition") {
        this.payDesignId = res.data.design_id;
        // 判断用户是否还有可用次数
        if (this.userInfo.number && this.userInfo.number > 0) {
          // 直接重新调起设计页
          this.reOpenCktDesign();
          return;
        }
        this.dialogVisible = true;
        return;
      }
    };
  },

  methods: {
    /// 支付状态回调
    statusChange (type) {
      this.handleClose();
      if (type == 1 && this.payDesignId) {
        // 支付成功 重新调起设计页
        this.reOpenCktDesign();
      }
    },

    // 重新打开设计页
    reOpenCktDesign () {
      const params = {
        app_id: this.signInfo['appId'],
        user_flag: this.signInfo['user_flag'],
        expire_time: Date.now() + 1000 * 1000,
        design_id: this.payDesignId,
        device_type: 1,
        package_id: "232",
        charge_type: 1,
        coop_material_limit: 2,
        coop_font_limit: 2,
        charging_template_limit: 2,
      };
      let singedParams = paramsDesign(params, this.app_skey);
      const design = new CktDesign(singedParams);
      design.open();
    },

    // 获取用户信息
    getUserInfo () {
      const token = this.$route.query.token;
      if (!token) {
        window.location.href = 'https://nav.csavs.com/login.html';
      }
      // 拿到token获取用户信息
      axios.post(
        'https://navadmin.csavs.com/api/ckt',
        {},
        {
          headers: { 'token': token },
        })
        .then(res => {
          if (res.status == 200 && res.data.code == 1) {
            this.userInfo = { token, ...res.data.data };
            // 存一份token在本地
            window.localStorage.setItem('ckt:token', token);
            // 获取app_skey
            this.getAppSkey();
            // 获取模板中心签名数据
            this.generateSignature();
          }
        }, error => {
          console.error(error);
          this.$message.error('用户身份信息校验失败，请重新登录');
          window.location.href = 'https://nav.csavs.com/login.html';
        });
    },

    /// 获取app_skey
    getAppSkey () {
      axios.post(
        'https://navadmin.csavs.com/api/ckt/getSecret',
        {},
        {
          headers: { 'token': this.userInfo.token },
        })
        .then(res => {
          if (res.status == 200 && res.data.code == 1) {
            this.app_skey = res.data.data;
          }
        }, error => {
          console.error(error);
          this.$message.error('获取app_skey失败');
        });
    },

    /// 获取模板中心签名数据
    generateSignature () {
      axios.post(
        'https://navadmin.csavs.com/api/ckt/getSign',
        {},
        {
          headers: { 'token': this.userInfo.token },
        })
        .then(res => {
          if (res.status == 200 && res.data.code == 1) {
            this.signInfo = res.data.data;
            // 创建模版中心
            this.createTemplateCenter();
          }
        }, error => {
          console.error(error);
          this.$message.error('获取签名信息失败');
        });
    },


    // 用户点击模板时触发
    templateItemClick (templateItem) {
      // console.log("用户点击模板时触发:", JSON.parse(JSON.stringify(templateItem)));
      const params = {
        app_id: this.signInfo['appId'],
        user_flag: this.signInfo['user_flag'],
        expire_time: Date.now() + 1000 * 1000,
        template_id: templateItem.templateId,
        device_type: 1,
        package_id: "232"
      };
      if (templateItem.price == 1) {
        // 付费模板 需要判断一下是前置付费还是后置付费（数据从用户信息获取）
        if (this.userInfo.pay_type == "1") {
          // 前置付费
          params.charge_type = 1;
        } else if (this.userInfo.pay_type == "2") {
          // 后置付费
          params.charge_type = 2;
        }
        params.coop_material_limit = 2;
        params.coop_font_limit = 2;
        params.charging_template_limit = 2;
      } else if (templateItem.price == 0) {
        // 免费模板
        params.charge_type = 0;
      }

      let singedParams = paramsDesign(params, this.app_skey);
      const design = new CktDesign(singedParams);
      design.open();
    },

    /// 手动关闭支付弹窗
    handleClose () {
      // 调用内部组件关闭定时器
      clearInterval(this.$refs.orderComp.intervalId);
      clearInterval(this.$refs.orderComp.intermiao);
      this.dialogVisible = false;
    },

    // 创建模版中心
    createTemplateCenter () {
      let option = {
        container: "#app",
        ...this.signInfo,
      };
      const instance = CktDesign.createTemplateCenter(option);
      instance.open().then(() => {
        // 筛选项变更时触发
        instance.onParamsChange((params) => { });
        // 用户点击模板时触发
        instance.onChooseTemplate((templateItem) => this.templateItemClick(templateItem));
      });
    }
  },
};
</script>

<style lang="less">
html,
body,
.root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 5vw;
    right: 5vw;
    top: 20px;
    background: #fff;

    .logo {
      width: 280px;
    }
  }
}

#app {
  width: 100%;
  height: 100%;
  margin-top: 104px;
}

#dialog {
  z-index: 9999999999999 !important;
}
</style>
