import CryptoJS from 'crypto-js';

export const getSign = (params, key) => {
  let pArr = [];
  for (let key in params) {
    pArr.push(`${key}=${params[key]}`);
  }
  return CryptoJS.MD5(pArr.sort().join('&') + key).toString().toUpperCase();
};

export const paramsDesign = (data, app_skey) => {
  let params = Object.assign(data, {});
  return {
    ...params,
    sign: getSign(params, app_skey)
  };
};
