export function downloadImage (url) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
  xhr.onload = function () {
    const fileBlob = xhr.response;
    console.log(fileBlob);
    const fileUrl = URL.createObjectURL(fileBlob);
    console.log(fileUrl);
    const ele = document.createElement('a');
    ele.setAttribute('href', fileUrl);
    ele.setAttribute('download', "");
    ele.click();
    ele.innerHTML = '下载';
    document.body.appendChild(ele);
  };
}